import {NgModule} from '@angular/core';
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {BenutzerResolver} from './resolver/benutzer.resolver';


const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  preloadingStrategy: PreloadAllModules,
};

const routes: Routes = [
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full'
  },
  {
    path: '',
    loadComponent: () => import('./modules/components/overview/overview.component').then(m => m.OverviewComponent),
    canActivate: [AuthGuard],
    resolve: [
      BenutzerResolver,
    ]
  },
  {
    path: 'error',
    loadComponent: () => import('./modules/pages/service-unavailable/service-unavailable.component').then(m => m.ServiceUnavailableComponent),
    canActivate: [AuthGuard],
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
