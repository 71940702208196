<mat-dialog-content>
  <div class="header">
    <b>Nutzer löschen?</b>
    <jf-button
      [variant]="'ghost'"
      [icon]="'close'"
      [iconPosition]="'icon-only'"
      (doClick)="exitDialog()"
      [disabled]="loading"
    ></jf-button>
  </div>

  <p>
    Diese Aktion lässt sich nicht rückgängig <br>
    machen, die Daten sind für immer <br>
    verloren.
  </p>

  <div class="actions">
    <jf-button
      [variant]="'primary'"
      [disabled]="loading"
      (doClick)="deleteUser()"
    >
      <span *ngIf="!loading; else deletingText">
        Endgültig löschen
      </span>

      <ng-template #deletingText>
        <span>Wird gelöscht </span>
      </ng-template>
    </jf-button>

    <jf-button
      [variant]="'outline'"
      [disabled]="loading"
      (doClick)="exitDialog()"
    >
      Abbrechen
    </jf-button>
  </div>
</mat-dialog-content>
