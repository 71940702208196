import {BenutzerState} from '../states/benutzer-state.interface';
import {createReducer, on} from '@ngrx/store';
import {BenutzerActions} from '../actions/benutzer.actions';


export const initialBenutzerState: BenutzerState = {
  benutzerDtos: [],
  benutzerColors: [
    '#333333', // INFO: Farbe für den Eigentümer
    '#E9E550',
    '#A8D757',
    '#4D9E89',
    '#5488A5',
    '#4E5CD1',
    '#2D0440',
    '#FF6767',
    '#FF2365',
    '#B2008B',
    '#680099',
    '#3900B2',
    '#004462',
    '#999999', // INFO: Fallback Farbe
  ],
  benutzerLoaded: false,
};

export const benutzerReducer = createReducer(
  initialBenutzerState,

  // INFO: Benutzer Laden Reducers

  on(
    BenutzerActions.readBenutzerSuccess,
    (state, action) => ({
      ...state,
      benutzerDtos: action.benutzerDtos,
      benutzerLoaded: true,
    })
  ),

);
