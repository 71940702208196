import {AppState} from '../states/app-state.interface';
import {createSelector} from '@ngrx/store';
import {BenutzerPortal} from '../../interfaces/benutzerPortal.interface';
import {identity} from 'rxjs';


export class BenutzerSelectors {

  private static select = {
    all: (state: AppState) => state.benutzerReducer.benutzerDtos,
    benutzerLoaded: (state: AppState) => state.benutzerReducer.benutzerLoaded,
    hauptverantwortlicher: (state: AppState) => state.benutzerReducer.benutzerDtos.find(user => user.self),
    colors: (state: AppState) => state.benutzerReducer.benutzerColors,
  };

  public static benutzerLoaded = createSelector(
    this.select.benutzerLoaded,
    identity,
  );

  public static sortedByErstellzeitpunkt = createSelector(
    this.select.all,
    this.select.colors,
    (benutzer, colors) => {
      // INFO: Eigentümer ermitteln.
      const selfBenutzer = [...benutzer].find(benutzer => benutzer.self);
      if (!selfBenutzer) return [];
      const sortedBenutzer = [selfBenutzer];

      // INFO: Benutzer sortieren
      [...benutzer].filter(benutzer => !benutzer.self)
        .sort((a, b) => new Date(a.erstellzeitpunkt!).getTime() - new Date(b.erstellzeitpunkt!).getTime())
        .every(benutzer => sortedBenutzer.push(benutzer));

      // INFO: Farben zuweisen
      return sortedBenutzer.map((benutzer, index) => {
        // INFO: Wenn der Index größer als die Länge von colors ist, den letzten Wert verwenden
        const color = index < colors.length ? colors[index] : colors[colors.length - 1];

        // INFO: Zuweisen der Farbe
        const currentBenutzer: BenutzerPortal = {...benutzer, color};

        // INFO: Return des erweiterten Benutzers
        return currentBenutzer;
      });
    }
  );

  public static sortedByNachname = createSelector(
    this.sortedByErstellzeitpunkt,
    benutzer => {
      // INFO: Eigentümer ermitteln.
      const selfBenutzer = [...benutzer].find(benutzer => benutzer.self);
      if (!selfBenutzer) return [];
      const sortedBenutzer = [selfBenutzer];

      // INFO: Benutzer sortieren
      [...benutzer].filter(benutzer => !benutzer.self)
        .sort((a, b) => a.persoenlicheAngaben.nachname.localeCompare(b.persoenlicheAngaben.nachname))
        .every(benutzer => sortedBenutzer.push(benutzer));

      // INFO: Return des erweiterten Benutzers
      return sortedBenutzer;
    }
  );

  public static hauptverantwortlicher = createSelector(
    this.select.hauptverantwortlicher,
    this.sortedByErstellzeitpunkt,
    (hauptverantwortlicher, sortedBenutzer) => sortedBenutzer.find(benutzer => benutzer.id === hauptverantwortlicher?.id),
  );

  public static nextColor = createSelector(
    this.select.all,
    this.select.colors,
    (benutzer, colors) => {
      const nextIndex = benutzer.length;
      // INFO: Wenn der nächste Index größer oder gleich der Länge von colors ist, den letzten Wert verwenden
      return nextIndex < colors.length ? colors[nextIndex] : colors[colors.length - 1];
    }
  );

}
