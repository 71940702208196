import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AppState} from '../store/states/app-state.interface';
import {Store} from '@ngrx/store';
import {BenutzerSelectors} from '../store/selectors/benutzer.selectors';
import {BenutzerActions} from '../store/actions/benutzer.actions';
import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class BenutzerResolver  {

  constructor(
    private store: Store<AppState>,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {

    this.store.select(BenutzerSelectors.benutzerLoaded).subscribe(loaded => {
      if (!loaded) {
        this.store.dispatch(BenutzerActions.readBenutzer());
      }
    });
  }
}
