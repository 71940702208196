import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Observable} from 'rxjs';
import {PortalMessageBroker} from './portal/portal-message-broker';
import {IntentActionService} from './services/intent.action.service';
import {PreloadModulesService} from './services/preload-modules.services';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private _destroyRef = inject(DestroyRef);
  private _portalMessageBroker = inject(PortalMessageBroker);
  private _oidcSecurityService = inject(OidcSecurityService);
  private _intentActionService = inject(IntentActionService);
  private _preloadModulesService = inject(PreloadModulesService);

  runningInPortal$: Observable<boolean>;

  constructor() {
    this.runningInPortal$ = this._portalMessageBroker.runningInPortal$.pipe(
      takeUntilDestroyed(this._destroyRef),
    );
  }

  ngOnInit(): void {
    /*
     * INFO: In der folgenden Zeile wird die preloadModules Methode des PreloadModulesService aufgerufen,
     * um die vordefinierten Module vorzuladen.
     */
    this._preloadModulesService.preloadModules();
  }

  doLogout(): void {
    this._oidcSecurityService.logoff();
  }

}
