import {BenutzerFormState} from '../../interfaces/benutzer-form-state.interface';
import {createReducer, on} from '@ngrx/store';
import {BenutzerFormActions} from '../actions/benutzer-form.actions';


export const initialBenutzerFormState: BenutzerFormState = {
  isModalOpen: false,
  formBlockPersonalDataValid: false,
  formBlockAppsValid: false,
  formIsTouched: false,
};

export const benutzerFormReducer = createReducer(
  initialBenutzerFormState,

  /*
   * INFO: Setzt Modal auf "offen" und speichert die übergebene BenutzerId.
   * Wird eine Benutzer-ID übergeben, ist davon auszugehen, dass ein Benutzer zum Bearbeiten
   * geöffnet wird. In dem Fall muss die Validierung initial korrekt sein.
   */
  on(
    BenutzerFormActions.openModal,
    (state, action) => ({
      ...state,
      isModalOpen: true,
      loadedBenutzerId: action.benutzerId,
    })
  ),

  // INFO: Setzt Modal auf "geschlossen" und löscht (falls vorhanden) die geladene BenutzerId.
  on(
    BenutzerFormActions.closedModal,
    (state) => ({
      ...initialBenutzerFormState,
    })
  ),

  // INFO: Aktualisiert personalFormData, sobald die Nutzer:in die Werte im Formular ändert.
  on(
    BenutzerFormActions.formValueChanges,
    (state, action) => ({
      ...state,
      personalFormData: action.personalFormData,
    })
  ),

  // INFO: Aktualisiert appsFormData, sobald die Nutzer:in die App-Auswahl ändert.
  on(
    BenutzerFormActions.updateAppSelection,
    (state, action) => ({
      ...state,
      appsFormData: action.appsFormData,
    })
  ),

  // INFO: Aktualisiert die Validierung des Personal-Formulars.
  on(
    BenutzerFormActions.updateBlockPersonalDataValidity,
    (state, action) => ({
      ...state,
      formBlockPersonalDataValid: action.isValid,
    })
  ),

  // INFO: Aktualisiert die Validierung des Apps-Formulars.
  on(
    BenutzerFormActions.updateBlockAppsValidity,
    (state, action) => ({
      ...state,
      formBlockAppsValid: action.isValid,
    })
  ),

  on(
    BenutzerFormActions.updateFormTouched,
    (state) => ({
      ...state,
      formIsTouched: true,
    })
  )
);
