import {Component, Inject, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {NGXLogger} from 'ngx-logger';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BenutzerPortalDTO} from '../../../openapi/benutzer-openapi';
import {BenutzerActions} from '../../../store/actions/benutzer.actions';


@Component({
  selector: 'app-benutzer-delete-dialog',
  templateUrl: './benutzer-delete-dialog.component.html',
  styleUrls: ['./benutzer-delete-dialog.component.scss']
})
export class BenutzerDeleteDialogComponent {

  @Input()
  public benutzer?: BenutzerPortalDTO;

  public loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { benutzerId: string },
    private dialog: MatDialogRef<BenutzerDeleteDialogComponent>,
    private store: Store,
    private logger: NGXLogger,
  ) {
    this.dialog.disableClose = true;
  }

  deleteUser(): void {
    const benutzerId = this.data.benutzerId;
    if (benutzerId) {
      this.loading = true;
      this.store.dispatch(BenutzerActions.deleteBenutzer({benutzerId}));
    } else {
      this.logger.error('cant trigger deleteUser caused by missing benutzerId');
      this.exitDialog();
    }
  }

  exitDialog(): void {
    this.store.dispatch(BenutzerActions.closeDeleteBenutzerDialog());
  }
}
