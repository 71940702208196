import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {
  AuthInterceptor as OidcAuthInterceptor,
  AuthModule,
  OidcSecurityService,
  OpenIdConfiguration,
  StsConfigHttpLoader,
  StsConfigLoader
} from 'angular-auth-oidc-client';
import {LoggerModule, NGXLogger, NgxLoggerLevel} from 'ngx-logger';
import {map, mergeMap, take, tap} from 'rxjs';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ConfigModule} from './config/config.module';
import {ConfigService} from './config/service/config.service';
import {environment} from '../environments/environment';
import {ApiModule as BenutzerApiModule, Configuration as BenutzerConfiguration} from './openapi/benutzer-openapi';
import {PortalMessageBroker} from './portal/portal-message-broker';
import {PortalTokenService} from './portal/portal-token.service';
import {AuthInterceptor} from './auth/auth.interceptor';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {RouterState, StoreRouterConnectingModule} from '@ngrx/router-store';
import {metaReducers, reducers} from './store/reducers/reducers';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {Router} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {registerLocaleData} from '@angular/common';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeDe from '@angular/common/locales/de';
import * as Sentry from '@sentry/angular';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import {filter} from 'rxjs/operators';
import {BenutzerEffects} from './store/effects/benutzer.effects';
import {BenutzerFormEffects} from './store/effects/benutzer-form.effects';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Interceptor} from './http-interceptors/interceptor';
import {ErrorpageEffects} from './store/effects/errrorpage.effects';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';



registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    MatIconModule,
    HttpClientModule,
    MatSnackBarModule,
    MatDialogModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatToolbarModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    // Config
    ConfigModule,
    // Logging
    LoggerModule.forRoot({
      level: NgxLoggerLevel.INFO, // default logging level
      enableSourceMaps: !environment.production,
    }),
    // Auth
    // TODO: Auth ggf. in eigenes Module auslagern
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: (configService: ConfigService) => {
          const configObservable = configService.loaded$

            .pipe(
              take(1),
              map(config => {
                const authConfig: OpenIdConfiguration = {
                  // loaded
                  authority: config.auth.authority,
                  clientId: config.auth.clientId,
                  scope: config.auth.scope,
                  logLevel: config.auth.logLevel,
                  renewTimeBeforeTokenExpiresInSeconds: config.auth.renewTimeBeforeTokenExpiresInSeconds,
                  // predefined
                  redirectUrl: window.location.origin,
                  postLogoutRedirectUri: window.location.origin,
                  responseType: 'code',
                  silentRenew: true,
                  triggerAuthorizationResultEvent: true,
                  ignoreNonceAfterRefresh: true,
                  useRefreshToken: true,
                  // INFO: Bei anpassungen auch AuthInterceptor.matchesSecureRoots() anpassen
                  secureRoutes: [config.benutzer.apiBaseUrl],
                };
                return authConfig;
              }),
            );
          return new StsConfigHttpLoader(configObservable);
        },
        deps: [ConfigService],
      },
    }),
    BenutzerApiModule.forRoot(() => new BenutzerConfiguration()),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictStateSerializability: true,
      }
    }),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal,
    }),
    EffectsModule.forRoot([
      BenutzerEffects,
      BenutzerFormEffects,
      ErrorpageEffects,
    ]),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
  ],
  exports: [
    AuthModule,
  ],
  providers: [
    OidcAuthInterceptor,
    {
      // INFO: Interceptor um automatisch da OAuth Token zu setzen
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      // INFO: Initializer das Logging
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService, logger: NGXLogger) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            tap(value => {
              logger.updateConfig({
                ...logger.getConfigSnapshot(),
                level: value.logging.level
              });
            }),
          );
      },
      deps: [ConfigService, NGXLogger],
      multi: true,
    },
    {
      // INFO: Initializer für Sentry Integration
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            tap(value => {
              const sentry = value.sentry;
              const info = environment.info;

              Sentry.init({
                enabled: sentry.enabled,
                debug: sentry.debug,
                environment: value.environment,
                release: info.commit,
                dsn: sentry.dsn,
                sampleRate: sentry.sampleRate,
                initialScope: {
                  extra: {
                    'info': info,
                  },
                },
              });
            }),
          );
      },
      deps: [ConfigService],
      multi: true,
    },
    {
      // INFO: Angular Sentry ErrorHandler
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      // INFO: Provider für Sentry TraceService
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      // INFO: Initializer um TraceService Tree Shakeable zu umgehen
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      // INFO: Initializer der die Base-URL setzt
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigService,
        benutzerConfiguration: BenutzerConfiguration,
      ) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            tap(value => {
              benutzerConfiguration.basePath = value.benutzer.apiBaseUrl;
            }),
          );
      },
      deps: [ConfigService, BenutzerConfiguration],
      multi: true,
    },
    {
      // INFO: Initializer für Portal integration die Base-URL setzt
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigService,
        portalMessageBroker: PortalMessageBroker,
        portalTokenService: PortalTokenService,
        oidcSecurityService: OidcSecurityService,
      ) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            mergeMap(config => {
              return fromPromise(portalMessageBroker.init(config.portal.targetOrigin))
                .pipe(map(() => config));
            }),
            mergeMap(config => {
              return fromPromise(portalTokenService.init(config.auth.renewTimeBeforeTokenExpiresInSeconds))
                .pipe(map(() => config));
            }),
            tap(() => {
              const runningEmbedded = portalMessageBroker.isRunningInPortal();
              Sentry.setTag('runningEmbedded', runningEmbedded);

              if (runningEmbedded) {
                const decoded = jwtDecode<JwtPayload>(portalTokenService.getAdnovaToken());
                const token = decoded as any;

                const email = token.email;
                const username = token.preferred_username;
                const name = token.name;

                Sentry.withScope(scope => {
                  let user = scope.getUser();
                  if (!user) {
                    user = {};
                  }
                  user.id = decoded.sub;
                  user.email = email;
                  user.username = username;
                  user['name'] = name;

                  scope.setUser(user);
                });
              } else {
                oidcSecurityService.getAccessToken()
                  .pipe(
                    filter(value => {
                      if (value) {
                        return true;
                      }
                      return false;
                    }),
                  )
                  .subscribe(accessToken => {
                    const decoded = jwtDecode<JwtPayload>(accessToken);
                    const token = decoded as any;

                    const email = token.email;
                    const username = token.preferred_username;
                    const name = token.name;

                    Sentry.withScope(scope => {
                      let user = scope.getUser();
                      if (!user) {
                        user = {};
                      }
                      user.id = decoded.sub;
                      user.email = email;
                      user.username = username;
                      user['name'] = name;

                      scope.setUser(user);
                    });
                  });
              }
            }),
          );
      },
      deps: [
        ConfigService,
        PortalMessageBroker,
        PortalTokenService,
        OidcSecurityService,
      ],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
