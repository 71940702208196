import {Injectable} from '@angular/core';

/**
 * @fileoverview Dieser Service ermöglicht das Vorladen spezifischer Module,
 * unabhängig von den in den Routen definierten.
 */

@Injectable({
  providedIn: 'root',
})
export class PreloadModulesService {
  constructor() {
  }

  /**
   * @description
   * Lädt das Benutzer Form Modul vor.
   * Diese Funktion wird beim Start der Anwendung aufgerufen und
   * lädt das Benutzer Form Modul, unabhängig von der Routing-Konfiguration.
   *
   * Durch Verwendung von setTimeout mit einer Verzögerung von 0 wird sichergestellt,
   * dass das Vorladen des Moduls nicht den Initialisierungsprozess der Anwendung blockiert.
   * Stattdessen wird es so schnell wie möglich ausgeführt, nachdem der aktuelle Call Stack abgearbeitet ist.
   */
  preloadModules(): void {
    setTimeout(() => {
      import('../modules/dialogs/benutzer-form-dialog/benutzer-form-dialog.module').then(m => m.BenutzerFormDialogModule);
    }, 0);
  }

}
